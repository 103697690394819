p {
  font-size: 16px;
}

.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.videoBg {
  width: 100vw;
  height: 90vh;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.videoContainer {
  height: 806px;
}

.menuContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.headlineContainer {
  display: grid;
  place-items: center;
  position: relative;
  top: -500px;
}

.removeLinkStyle {
  text-decoration: none;
}

.videoHeadline {
  color: white;
  font-weight: bold;
  text-align: center;
}

.videoLink {
  text-decoration: underline;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
}

.headlineLarge {
  font-size: 3rem;
}

.imgContainer {
  padding: 0px;
  margin: 0px;
}

.textContainer {
  padding: 0% 5% 0% 5%;
  margin: 0px;
}

.navLogo {
  width: 50px;
  height: 50px;
}

.navLinks {
  margin-left: 20px;
}

.footerIcons {
  margin-top: 20px;
  margin-left: 20px;
}

.footerDiv {
  margin-top: 20px;
  margin-left: 20px;
}
.menuImg {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.menuName {
  font-size: 2vh;
  font-weight: bold;
  margin-top: 75px;
  margin-bottom: 10px;
}

.guideLine {
  display: grid;
  place-items: center;
  position: relative;
  height: 300px;
}

.hero-image {
  background-image: url("./starbucks8.jpeg");
  height: 92vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.grid-center {
  display: grid;
  place-items: center;
}

.about-container {
  width: 100%;
  margin: 0 auto 0 auto;
}

.drink-img {
  width: 150px;
  border-radius: 50%;
}

.hr-space {
  margin: 100px 0 100px 0;
}

.about-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.customizationContainer {
  border-style: solid;
  border-width: 1px;
  border-color: darkgray;
  padding: 10px 20px 5px 20px;
  border-radius: 10px;
}

.modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  right: 0px;
}

.form-select:focus {
  outline: none;
  box-shadow: none;
}

.form-select {
  font-size: 20px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
